<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh  v-model="refreshing" @refresh="onRefresh">
      <div class="teamw">
        <van-search v-model="keyWord" @search="onSearch" placeholder="请输入搜索关键词" />
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
          offset="100"
          :immediate-check="false"
        >
        <van-radio-group v-model="isAll">
          <van-cell-group>
            <van-cell clickable @click="isAll = '1'">
              <template #title>
                <div class="cell-it">
                  <van-radio name="1" checked-color="#DABA88" />
                  <span>全部商品</span>
                </div>
              </template>
            </van-cell>
            <van-cell  clickable @click="isAll = '2'">
              <template #title>
                <div class="cell-it">
                  <van-radio name="2" checked-color="#DABA88" />
                  <span>单选商品</span>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="isOne">
          <van-cell v-for="item in list" :key="item" clickable @click="isOne = item" >
            <template #title>
                <div class="cell-it child">
                  <van-radio class="cell-it-radio" :name="item" checked-color="#DABA88" />
                  <div class="cell-goods">
                    <img class="cell-goods-avatar" src="https://x.jzbull.com/dsx-h5/p1.png" alt="">
                    <div class="cell-goods-info">
                      <div class="cell-goods-info-name">llY819159897</div>
                      <div class="cell-goods-info-level">一级代理</div>
                    </div>
                  </div>
                </div>
            </template>
          </van-cell>
        </van-radio-group>
        </van-list>
      </div>
    </van-pull-refresh>
    <div class="foot-btns">
      <div class="foot-btnbox">
        <button class="foot-btn"  @click="goBack">确定</button>
      </div>
      <!-- <button class="foot-btn"  @click="goBack">确定</button> -->
    </div>
  </div>
</template>
<script>
import { reactive, toRefs,watch} from 'vue'
import { List, PullRefresh,RadioGroup, Radio } from 'vant'
import SimpleHeader from '@/components/SimpleHeader'
import { useRouter } from 'vue-router'
export default {
  name: 'selectGoods',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    SimpleHeader,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  setup() {
    let router =  useRouter()
    let state = reactive({
      keyWord:'',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      isAll:'1',
      isOne:''
    })
    const getData = () => {
      setTimeout(() => {
        if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          state.list.push(state.list.length + 1);
        }
        state.loading = false;

        if (state.list.length >= 20) {
          state.finished = true;
        }
      }, 1000);

    };
    const onSearch = () => {
      state.page = 1
      state.list = []
      getData()
    }
    const onRefresh = () => {
      // 清空列表数据
      state.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true;
      getData();
    };

    watch(() => state.isAll, (newValue, oldValue) => {
      if (newValue == '1') {
        state.isOne = ''
      } else {
        state.isOne = 1
      }

    })

    const goBack = () => {
      router.go(-1)
    }

    const goTo = (r, query) => {
      router.push({ path: r, query: query || {} })
    }
    return {
      ...toRefs(state),
      getData,
      onRefresh,
      goBack,
      onSearch
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: #fff;
  min-height: 100%;
  // height: auto;
  .cell-it{
    display: flex;
    align-items: center;
    >span{
      margin-left: 10px;

    }
    &.child{
      width: 100%;
      padding-left: 30px;
    }
    &-radio{
      display: inline-block;
      height: 24px;
      width: 24px;
    }
  }
   .cell-goods{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
    &-avatar{
      width: 45px;
      height: 45px;
      border-radius: 45px;
    }
    &-info{
      flex: 1;
      padding: 5px 10px;
      // display: flex;
      // flex-direction: column;
      &-name{
        font-weight: bold;
      }
      &-level{
        margin-top: auto;
      }
    }
  }
}

</style>
